<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <header class="container-header-index">
                <!-- <div class="h-index-left"></div> -->
                <!-- <div class="h-index-right">
                    <input class="h-search-input" placeholder="Buscar…">
                    <button class="h-btn-seacrh" @click="searchItemInList()">Buscar</button>
                </div> -->
            </header>
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Transparencia y acceso a la información pública</h5>
                            </div>
                        </div>
                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Numero identificacion</th>
                                    <th class="th-colum-item">Nombres</th>
                                    <th class="th-colum-item">Apellidos</th>
                                    <th class="th-colum-item">Correo electronico</th>
                                    <th class="th-colum-item">Telefono fijo</th>
                                    <th class="th-colum-item">Telefono celular</th>
                                    <th class="th-colum-item">Nombre PQRS</th>
                                    <th class="th-colum-item">Nombre Servicio</th>
                                    <th class="th-colum-item">Descripcion</th>
                                </tr>
                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in listPqr" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.identification_number }}</td>
                                        <td class="th-colum-item">{{ item.names }}</td>
                                        <td class="th-colum-item">{{ item.surnames }}</td>
                                        <td class="th-colum-item">{{ item.email }}</td>
                                        <td class="th-colum-item">{{ item.landline }}</td>
                                        <td class="th-colum-item">{{ item.cell_phone }}</td>
                                        <td class="th-colum-item">{{ item.select_option }}</td>
                                        <td class="th-colum-item">{{ item.select_service }}</td>
                                        <td class="th-colum-item">{{ item.description }}</td>
                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <td class="td-container-action td-success">
                                            <router-link class="text-white"
                                                :to="{ name: 'detailPqr', params: { _id: item._id } }">
                                                <i class="el-icon-success icon-action"></i>
                                            </router-link>
                                        </td>
                                        <td class="td-container-action td-danger" @click="showConfirmation(index)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db } from '../../../../firebaseConfig';
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader
    },
    data() {
        return {
            listPqr: [],
            isLoading: false,
            dataGetDocs: []
        }
    },
    mounted() {
        this.getAllPqr()
    },
    methods: {
        async getAllPqr() {
            this.isLoading = true
            await db.collection('infoPqr')
                .get()
                .then((querySnapshot) => {
                    this.dataGetDocs = querySnapshot.docs;
                    const pqr = [];
                    querySnapshot.forEach((doc) => {
                        pqr.push(doc.data());
                    });
                    this.listPqr = pqr;
                    this.isLoading = false
                })
        },

        showConfirmation(doc_id) {
            this.$swal
                .fire({
                    title: 'Eliminar PQR',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteStorageFolder(doc_id);
                });
        },

        async deleteStorageFolder(childName) {
            this.isLoading = true
            try {
                await db.collection('infoPqr')
                    .doc(this.dataGetDocs[childName].id)
                    .delete()
                    .then(() => {
                        this.isLoading = false
                        this.$swal
                            .fire({
                                title: 'Acción exitosa',
                                text: 'PQR eliminada exitosamente',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                confirmButtonColor: "#45A825",
                            })
                        return this.getAllPqr()
                    })
            } catch (error) {                
                this.isLoading = false
            }
        }
    },
}
</script>